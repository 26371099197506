const I18NextHttpBackend = require("i18next-http-backend/cjs");

// Not importing from Env bc that is in TS(->mjs mode)
// LMK if it can be done?
// This is the only file that needs this var
// const { TRANSLATION_SERVICE_URL } = require("src/Env");
// const { getConfig } = require("next/config");
// const { publicRuntimeConfig } = getConfig();

// const TRANSLATION_SERVICE_URL =
//   publicRuntimeConfig.TRANSLATION_SERVICE_URL ||
//   "https://translations.outschool.com";

const TRANSLATION_SERVICE_URL =
  (process && process.env.TRANSLATION_SERVICE_URL) ||
  "https://translations.outschool.com";

module.exports = {
  i18n: {
    defaultLocale: "en",
    localeDetection: false,
    locales: ["en", "ko", "ja", "zh-TW", "es"],
    defaultLanguage: "en",
    fallbackLng: ["en"],
  },
  debug: false,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  ns: [
    "ssr-client",
    "shared",
    "ui-components-contentful",
    "ui-components-shared",
    "ui-components-website",
  ],
  defaultNS: "ssr-client",
  keySeparator: "\\",
  nsSeparator: "\\",
  compatibilityJSON: "v3",
  parseMissingKeyHandler(key, defaultValue) {
    return (defaultValue ?? key).replace(/.*\\([^\\]+)$/, "$1");
  },
  serializeConfig: false,
  backend: {
    referenceLng: "en",
    loadPath: (languages, namespaces) => {
      const [language] = languages;
      const [namespace] = namespaces;
      const ns = namespace.split("\\").shift();
      return `${TRANSLATION_SERVICE_URL}/${ns}+(${language}).json`;
    },
  },
  use: [I18NextHttpBackend],
};
