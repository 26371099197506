import { localizeTo } from "@/src/lib/localizeLinks";
import { styled } from "@outschool/backpack";
import { queryStringToObject } from "@outschool/routes";
import { useImpressionTracking } from "@outschool/ui-legacy-component-library";
import { LinkProps } from "@outschool/ui-utils";
import lodashOmit from "lodash/omit";
import { useRouter } from "next/router";
import * as React from "react";

export function addStateToLocation(location, newState) {
  if (!location) {
    return location;
  }
  if (typeof location === "string") {
    const [pathname, queryParams] = location.split("?");

    if (queryParams) {
      const query = queryStringToObject(queryParams);

      return { pathname, state: newState || {}, query };
    }

    return { pathname: location, state: newState || {} };
  }
  if (typeof location === "object") {
    return { ...location, state: { ...(location.state || {}), ...newState } };
  }
  if (typeof location === "function") {
    return oldLocation => addStateToLocation(location(oldLocation), newState);
  }
  return location;
}

const StyledA = styled("a")({});

function Link(
  {
    to,
    target,
    children,
    style,
    onClick,
    trackingName,
    trackingUniqueId,
    trackingViewEventName,
    trackingTouchEventName,
    linkAlreadyTracked,
    sharedProperties = {},
    // eslint-disable-next-line
    useLinkTracking = false,
    disabled = false,
    trackViews = false,
    ...props
  },
  ref
) {
  // const { expectedClientVersion } = useGraphqlContext();
  const expectedClientVersion = "0.0";

  const [impressionNode, setImpressionNode] = React.useState();
  const { trackTouch } = useImpressionTracking({
    node: impressionNode,
    trackingLabel: trackingName,
    alreadyTracked: linkAlreadyTracked,
    uniqueId: trackingUniqueId,
    trackingEventName: trackingTouchEventName,
    impressionEventName: trackingViewEventName,
    sharedProperties,
    trackViews,
  });
  const { locale, defaultLocale } = useRouter();
  const localizedTo = localizeTo(to, locale, defaultLocale);

  /*
   *  const trackedTo = React.useMemo(() => {
   *    if (!trackingName || !useLinkTracking) {
   *      return to;
   *    }
   *
   *    return addStateToLocation(to, {
   *      linkTracking: {
   *        linkType: trackingName,
   *        linkImpressionId: impressionId,
   *      },
   *    });
   *  }, [to, trackingName, impressionId, useLinkTracking]);
   */

  return (
    <StyledA
      ref={node => {
        if (node && node !== impressionNode) {
          // @ts-ignore
          setImpressionNode(node);
          if (typeof ref === "function") {
            ref(node);
          } else if (ref && ref.current !== node) {
            ref.current = node;
          }
        }
      }}
      href={localizedTo}
      target={target}
      style={style}
      onClick={e => {
        if (disabled) {
          e.preventDefault();
        } else {
          if (trackingName) {
            trackTouch();
          }
          handleClick(e, expectedClientVersion, onClick);
        }
      }}
      {...lodashOmit(props, [
        "reloadDocument",
        "trackingEventName",
        "impressionEventName",
        "data-testid",
      ])}
    >
      {children}
    </StyledA>
  );
}

function handleClick(e, expectedClientVersion, onClick) {
  // TODO: Research on this ordering. It makes sense that we check
  //       if the default is prevented and only way is to trigger the
  //       onClick method, however, I am not 100% sure if this is the
  //       way events are handled by default.
  onClick && typeof onClick === "function" && onClick(e);
}

export default React.forwardRef<unknown, LinkProps>(Link);
