import {
  GIT_COMMIT,
  KAFKA_REST_PROXY,
  OUTSCHOOL_ENV,
  SEGMENT_WRITE_KEY,
  UI_ANALYTICS_DEBUG,
  UI_ANALYTICS_DEV_MODE,
  UI_ANALYTICS_DISABLED_INTEGRATION_LIST,
  UI_ANALYTICS_EVENT_QUEUE_LIMIT,
  UI_ANALYTICS_LOAD_TIME_LIMIT,
  isProduction,
} from "@/src/Env";
import { Component, Priority } from "@outschool/ownership-areas";
import { init } from "@outschool/ui-analytics";

export function initAnalyticsPackage() {
  if (typeof window === "undefined") {
    return;
  }

  init({
    app: "server-rendered-website",
    debug: UI_ANALYTICS_DEBUG,
    devMode: UI_ANALYTICS_DEV_MODE,
    disabledIntegrationList: UI_ANALYTICS_DISABLED_INTEGRATION_LIST,
    env: OUTSCHOOL_ENV,
    eventQueueLimit: UI_ANALYTICS_EVENT_QUEUE_LIMIT,
    gitCommitId: GIT_COMMIT,
    onError,
    loadTimeLimit: UI_ANALYTICS_LOAD_TIME_LIMIT,
    performance: getPerformanceConfig(),
    segmentWriteKey: SEGMENT_WRITE_KEY,
    kafkaRestProxy: KAFKA_REST_PROXY,
  });
}

function getPerformanceConfig() {
  const pages = [
    {
      name: "class",
    },
    {
      name: "group",
    },
  ];

  const observers = [
    { type: "first-input" },
    { type: "largest-contentful-paint" },
    { type: "layout-shift" },
    {
      type: "measure",
      label: "hydration",
    },
    {
      type: "measure",
      label: "os-page-load",
      pages: pages.map(page => page.name),
    },
    {
      type: "measure",
      label: "ttfb",
    },
  ];

  return {
    observers,
    pages,
  };
}

function onError(error: Error) {
  OsPlatform.captureError(error, {
    tags: {
      component: Component.Tracking,
      priority: Priority.P3,
    },
  });

  /*
   * console.log rather than console.error to avoid double event reporting to
   * Sentry.
   */
  if (!isProduction) {
    console.log(error);
  }
}
