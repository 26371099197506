import {
  CF_ACCESS_CLIENT_ID,
  CF_ACCESS_CLIENT_SECRET,
  OUTSCHOOL_API_URL,
  isStaging,
} from "@/src/Env";
import { I18nLocale } from "@outschool/localization";
import { usePageContext } from "@outschool/ui-analytics";
import { ApolloProvider } from "@outschool/ui-apollo";
import { createApolloClient } from "@outschool/ui-gql";
import { useRouter } from "next/router";
import React, { PropsWithChildren, useMemo } from "react";

const uri = `${OUTSCHOOL_API_URL}/graphql`;

export function createBackendApolloClient(locale: string) {
  const extraHeaders = {};

  if (isStaging) {
    extraHeaders["CF-Access-Client-Id"] = CF_ACCESS_CLIENT_ID;
    extraHeaders["CF-Access-Client-Secret"] = CF_ACCESS_CLIENT_SECRET;
  }

  return createApolloClient({
    name: "os-server-rendered-website-server",
    clientVersion: 1,
    uri,
    extraHeaders,
    ssrMode: true,
    locale: locale as I18nLocale,
  });
}

function createFrontendApolloClient(locale: I18nLocale, pageContextRef?: any) {
  return createApolloClient({
    name: "os-server-rendered-website-client",
    clientVersion: 1,
    uri,
    ssrMode: false,
    locale,
    pageContextRef,
  });
}

export function SsrApolloProvider({ children }: PropsWithChildren<{}>) {
  const pageContext = usePageContext();
  const pageContextRef = React.useRef(pageContext);
  pageContextRef.current = pageContext;

  const router = useRouter();
  const locale = router.locale as I18nLocale;
  const client = useMemo(() => {
    return typeof window === "undefined"
      ? createBackendApolloClient(locale)
      : createFrontendApolloClient(locale, pageContextRef);
  }, [locale]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
