import { CurrencyCode } from "@outschool/gql-backend-generated";
import { useLookupIP } from "@outschool/iplookup-client";
import { useLocalStorageState } from "@outschool/local-storage";
import {
  COUNTRIES_INFO,
  CountryCode,
  CurrencyLocalizationContext,
  I18nLocale,
  LocaleProvider,
  SELECTED_CURRENCY_CODE_LOCAL_STORAGE_KEY,
  SetCurrencyLocalizationContext,
  getCountryFromCurrency,
  getCurrencyCodeFromLocaleAndIP,
  useLocale,
} from "@outschool/localization";
import React from "react";

const CurrencyProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const locale = useLocale();
  const { ipInfo, ipInfoLoaded } = useLookupIP();

  const [selectedCurrencyLocalStorage, setSelectedCurrencyLocalStorage] =
    useLocalStorageState<CurrencyCode | null>(
      SELECTED_CURRENCY_CODE_LOCAL_STORAGE_KEY,
      null
    );

  React.useEffect(() => {
    // For new visitors to the site who do not have a currency saved in local storage,
    // use combination of user IP and locale to set a default currency
    if (!selectedCurrencyLocalStorage && ipInfoLoaded) {
      setSelectedCurrencyLocalStorage(
        getCurrencyCodeFromLocaleAndIP(locale, ipInfo)
      );
    }
  }, [
    selectedCurrencyLocalStorage,
    setSelectedCurrencyLocalStorage,
    locale,
    ipInfoLoaded,
    ipInfo,
  ]);

  // Map to get country info associated with the currency set in local storage,
  // and default to US if there's no match
  const countryCode: CountryCode =
    getCountryFromCurrency(selectedCurrencyLocalStorage) || CountryCode.US;
  const selectedCountryInfo = COUNTRIES_INFO[countryCode];

  const setCurrency = React.useCallback(
    async (newCurrencyCode: CurrencyCode) => {
      try {
        setSelectedCurrencyLocalStorage(newCurrencyCode);
      } catch (err) {
        console.error(err);
      }
    },
    [setSelectedCurrencyLocalStorage]
  );

  return (
    <SetCurrencyLocalizationContext.Provider value={{ setCurrency }}>
      <CurrencyLocalizationContext.Provider
        value={{
          currencyCode: selectedCurrencyLocalStorage || CurrencyCode.Usd,
          countryCode,
          selectedCurrencyCountry: selectedCountryInfo,
          isLoading: false,
          hasLoaded: true,
        }}
      >
        {children}
      </CurrencyLocalizationContext.Provider>
    </SetCurrencyLocalizationContext.Provider>
  );
};

export const LocalizationProvider = React.memo(
  ({
    children,
    userSelectedLocale,
  }: React.PropsWithChildren<{ userSelectedLocale?: I18nLocale }>) => (
    <LocaleProvider userSelectedLocale={userSelectedLocale}>
      <CurrencyProvider>{children}</CurrencyProvider>
    </LocaleProvider>
  )
);
