import { I18nLocale } from "@outschool/localization";

export function getDayjsLocale(locale: I18nLocale | string) {
  switch (locale) {
    case I18nLocale.Ko:
      // Not sure why but leaving off the ".js" breaks things
      return import("dayjs/locale/ko.js");
    case I18nLocale.Ja:
      return import("dayjs/locale/ja.js");
    case I18nLocale.ZhTw:
      return import("dayjs/locale/zh-tw.js");
    case I18nLocale.Es:
      return import("dayjs/locale/es.js");
    case "es":
      return import("dayjs/locale/es.js");
    case "de":
      return import("dayjs/locale/de.js");
    case "zh-CN":
      return import("dayjs/locale/zh-cn.js");
    case I18nLocale.En:
    default:
      return new Promise<"en">(res => res("en"));
  }
}
