import { useRouter } from "next/router";
import React from "react";

type PageDefinition = {
  category?: string;
  name?: string;
};

type RouteDefinitionMap = {
  [pathname: string]: PageDefinition;
};

const ROUTE_MAP: RouteDefinitionMap = {
  "/[deviceType]/classes/[activitySlug]": {
    name: "class",
  },
  "/[deviceType]/groups/[activitySlug]": {
    name: "group",
  },
  "/[deviceType]": {
    name: "home",
  },
};

interface PageInfo {
  name?: string;
  category?: string;
  path?: string;
}

export default function usePageInfo() {
  const { asPath, isReady, pathname } = useRouter();
  const [info, setInfo] = React.useState<PageInfo>({});

  React.useEffect(() => {
    if (!isReady) {
      return;
    }

    setInfo(getPageInfo(pathname, asPath));
  }, [asPath, isReady, pathname]);

  return info;
}

function getPageInfo(key: string, fullPath: string): PageInfo {
  const [path] = fullPath.split("?");
  const name = ROUTE_MAP[key]?.name;
  const category = ROUTE_MAP[key]?.category;

  return {
    category,
    name,
    path,
  };
}
