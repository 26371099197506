import { UtmCls } from "@outschool/attribution";
import { hrefToQueryString, queryStringToObject } from "@outschool/routes";
import Cookies from "js-cookie";
import lodashClone from "lodash/clone";
import { validate as isUuid } from "uuid";

/**
 * These functions store and retrieve attribution on the client that persists across multiple
 * browser sessions. They use Segment traits, which get persisted in localStorage.
 */
export async function updateFromLandingPageLocation(
  location,
  referer,
  analytics
) {
  if (Cookies.get("ignoreAttribution")) {
    Cookies.remove("ignoreAttribution");
    return;
  }
  const newAttribution = createAttribution({
    location,
    referer,
    anonymousId: await analytics.anonymousId(),
  });
  const oldAttribution = (await analytics.traits()) ?? null;
  if (hasAttribution(oldAttribution)) {
    // Attribution was previously set already
    return;
  }
  return newAttribution;
}

function scrubUsid(usid) {
  return usid?.split("#")?.[0];
}
export interface Attribution {
  created_at?: Date;
  referer?: string;
  landingPage?: string;
  anonymousId?: string;
  addressBar?: boolean;
  addressBarUserSlugId?: string;
  activity_uid?: string;
  usid?: string;
  ruid?: string;
  campaign?: string;
  content?: string;
  source?: string;
  medium?: string;
  term?: string;
  // Japan tracking pixel
  a8?: string;
}

function createAttribution({ location, referer, anonymousId }) {
  const utm = new UtmCls(location.href);
  const attribution: Attribution = utm.isValid() ? lodashClone(utm) : {};
  attribution.created_at = new Date();
  attribution.referer = referer;
  attribution.landingPage = location.href;
  attribution.anonymousId = anonymousId;

  if (utm.isValid() || referer) {
    const { ruid, usid: rawUsid } = queryStringToObject(
      hrefToQueryString(location.href)
    );
    // edge case: scrub the users # out of the usid
    const usid = scrubUsid(rawUsid);
    attribution.ruid = ruid;
    // TODO: A bug caused some share urls to have usid to set to user.uid
    //       This code block will set ruid to user.uid instead of usid
    if (usid && isUuid(usid)) {
      attribution.ruid = usid;
    } else {
      attribution.usid = usid;
    }

    const activity_uid = (location.href.match(
      /(?:activity|activities)\/([a-zA-Z0-9\-]{36})/
    ) || [])[1];

    if (activity_uid) {
      attribution.activity_uid = activity_uid;
    }
  }

  return attribution;
}

function hasAttribution(attribution?: Attribution) {
  attribution = attribution || {};
  const { campaign, source, medium, term, content, referer } = attribution;
  return Boolean(campaign || source || medium || term || content || referer);
}
