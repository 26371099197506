import { localizeTo } from "@/src/lib/localizeLinks";
import { NavigateFunction } from "@outschool/ui-utils";
import { useRouter } from "next/router";
import React from "react";

/**
 * An SSR-specific implementation of `useNavigation`. Each client-side app that uses
 * the NavigationProvider should have its own implementation of this.
 *
 * DO NOT IMPORT DIRECTLY. Import `useNavigation` from `@outschool/ui-utils` instead.
 */
export function useSSRNavigation() {
  const router = useRouter();

  return React.useCallback<NavigateFunction>(
    /**
     * Navigate to the URL provided. Uses window methods (`open`, `location.assign`, etc) for internal
     * URLS, and react-router (`history` methods) for internal URLs. Retains the current site locale.
     * @param linkTo URL to visit
     * @param options Config options
     * @param options.hardNav Override to use window methods (fully reload the page) even for internal URLS
     * @param options.newTab When using window navigation, open URL in a new tab (default is same tab)
     * @param options.replace Replace the current history entry instead of adding a new one. Applies to both
     * `window` and `history` navigation. Disables using the back button to return to the previous URL.
     */
    function navigate(linkTo, { hardNav, replace, newTab } = {}) {
      // For external URLs, always use window navigation
      const to = localizeTo(linkTo, router.locale, router.defaultLocale);
      if (/^https?/.test(to) || hardNav) {
        newTab
          ? window.open(to, "_blank")
          : window.location[replace ? "replace" : "assign"](to);
      } else {
        // Router will deal with the locale if needed
        router[replace ? "replace" : "push"](to);
      }
    },
    [router]
  );
}
