import {
  ReferrerAttribution,
  updateReferrerAttribution,
} from "@outschool/attribution";
import { learnerHost, websiteHost } from "@outschool/routes";
import { useAnalytics } from "@outschool/ui-analytics";
import React, { PropsWithChildren } from "react";

// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { isDevelopment, isTest } from "../../../Env";
import { updateFromLandingPageLocation } from "../lib/Attribution";

type Context = {
  referrerAttribution: ReferrerAttribution | null;
  identified: boolean;
};

const AttributionContext = React.createContext<Context>({
  referrerAttribution: null,
  identified: false,
});

export const AttributionProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const isInitialPageLoad = React.useRef<boolean>(true);
  const analytics = useAnalytics();
  const [identified, setIdentified] = React.useState(false);
  const [referrerAttribution, setReferrerAttribution] =
    React.useState<null | ReferrerAttribution>(null);

  React.useEffect(() => {
    async function updateAttribution() {
      if (identified || analytics.pending) {
        return;
      }

      setReferrerAttribution(
        updateReferrerAttribution({
          originalReferrer: document.referrer,
          originalLocation: window.location,
          currentLocation: window.location,
          useSecureCookie: !(isTest || isDevelopment),
          hostPrefix: websiteHost(),
          learnerHostPrefix: learnerHost(),
          isInitialPageLoad: isInitialPageLoad.current,
        })
      );

      isInitialPageLoad.current = false;

      const attribution = await updateFromLandingPageLocation(
        window.location,
        window.document.referrer,
        analytics
      );

      if (!attribution) {
        return;
      }

      setIdentified(true);
    }

    updateAttribution();
  }, [analytics, identified]);

  const value = {
    referrerAttribution,
    identified,
  };

  return (
    <AttributionContext.Provider value={value}>
      {children}
    </AttributionContext.Provider>
  );
};

export const useAttributionContext = () => {
  return React.useContext(AttributionContext);
};

export const useReferrerAttribution = () => {
  return React.useContext(AttributionContext).referrerAttribution;
};
