import { Box, Button, Typography } from "@outschool/backpack";
import {
  ExperimentOverridesQuery,
  ExperimentOverridesQueryVariables,
} from "@outschool/gql-frontend-generated";
import { faArrowDown, faArrowUp, faTimes } from "@outschool/icons";
import { useQueryWithPreviousData } from "@outschool/ui-apollo";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { useServerExperimentContext } from "@outschool/ui-experiments";
import {
  Card,
  IconButton,
  Select,
} from "@outschool/ui-legacy-component-library";
import gql from "graphql-tag";
import lodashKeys from "lodash/keys";
import lodashOmit from "lodash/omit";
import lodashToPairs from "lodash/toPairs";
import React from "react";

import { FEATURE_FLAGS, isDevelopment, isStaging } from "../../../Env";
import useLocalStorageState from "../../shared/hooks/useLocalStorageState";

export function useExperimentOverridesEnabled() {
  return !!FEATURE_FLAGS.EXPERIMENT_OVERRIDE || isDevelopment || isStaging;
}

function stringifyChoices(choices) {
  return choices.map(choice =>
    typeof choice === "string" ? choice : JSON.stringify(choice)
  );
}

function ExperimentOverride({
  experimentOverrideOptions,
  experimentAssignments,
  experimentOverrides,
  setExperimentOverride,
  label,
}: {
  experimentOverrideOptions: Record<string, Record<string, readonly string[]>>;
  experimentAssignments: Record<string, Record<string, string>>;
  experimentOverrides: Record<string, Record<string, string>>;
  setExperimentOverride(
    experimentName: string,
    parameterName: string,
    variant: string
  ): void;
  label: string;
}) {
  const [open, setOpen] = React.useState(false);

  const allKeys = lodashKeys(experimentOverrideOptions);

  const [selectedExperiment, setSelectedExperiment] =
    useLocalStorageState<string>(`OverrideSelectedExperiment-${label}`);

  const toggleOpen = () => setOpen(!open);

  if (
    open &&
    selectedExperiment &&
    !experimentOverrideOptions[selectedExperiment]
  ) {
    setSelectedExperiment(undefined);
  }

  return (
    <Card
      onClick={() => {
        if (!open) {
          toggleOpen();
        }
      }}
      sx={{ cursor: !open ? "hover" : "default", p: "small", m: "small" }}
    >
      <Box
        flex
        sx={{
          flexDirection: "column",
        }}
      >
        <Button
          onClick={toggleOpen}
          sx={{
            marginX: "auto",
          }}
        >
          {label}
        </Button>
        {open ? (
          <Box
            flex
            sx={{
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Typography variant="inherit">Experiment Override</Typography>
            <Box
              flex
              sx={{
                flexDirection: "column",
              }}
            >
              <Typography variant="inherit">Select Experiment</Typography>
              <Select
                options={["No Experiment Selected", ...allKeys.sort()]}
                value={selectedExperiment || "No Experiment"}
                onChange={setSelectedExperiment}
                sx={{
                  width: "100%",
                }}
              ></Select>
            </Box>

            {experimentOverrideOptions[selectedExperiment] ? (
              lodashToPairs(experimentOverrideOptions[selectedExperiment]).map(
                ([parameter, values]: [string, readonly string[]]) => (
                  <Box
                    flex
                    sx={{
                      flexDirection: "column",
                      alignItems: "start",
                    }}
                    key={parameter}
                  >
                    <Typography variant="inherit">
                      Assignment:{" "}
                      {experimentAssignments[selectedExperiment]?.[parameter]}
                    </Typography>
                    <Typography variant="inherit">
                      Override:{" "}
                      {experimentOverrides[selectedExperiment]?.[parameter]}
                    </Typography>
                    <Select
                      handleChangeOnBlur={false}
                      options={["", ...stringifyChoices(values)]}
                      value={
                        experimentOverrides[selectedExperiment]?.[parameter] ??
                        ""
                      }
                      onChange={(e: string) => {
                        setExperimentOverride(selectedExperiment, parameter, e);
                      }}
                    />
                  </Box>
                )
              )
            ) : selectedExperiment ? (
              <Typography variant="inherit">No parameters!</Typography>
            ) : null}
          </Box>
        ) : null}
      </Box>
    </Card>
  );
}

const ExperimentOverridesGql = gql`
  query ExperimentOverrides {
    experimentOverrides {
      name
      variants
    }
  }
`;

function ServerSideExperimentOverride() {
  const { experimentAssignments, experimentOverrides, setExperimentOverrides } =
    useServerExperimentContext();
  const areOverridesEnabled = useExperimentOverridesEnabled();
  const { data: experimentOverridesData } = useQueryWithPreviousData<
    ExperimentOverridesQuery,
    ExperimentOverridesQueryVariables
  >(ExperimentOverridesGql, {
    skip: !areOverridesEnabled,
  });
  const experimentOverridesOptions = React.useMemo(
    () =>
      (experimentOverridesData?.experimentOverrides ?? []).reduce(
        (expObj, exp) => {
          expObj[exp.name] = { Variant: exp.variants };
          return expObj;
        },
        {}
      ),
    [experimentOverridesData?.experimentOverrides]
  );
  const experimentAssignmentsFmted: Record<string, Record<string, string>> = {};
  if (!!experimentAssignments) {
    for (const expName of Object.keys(experimentAssignments)) {
      experimentAssignmentsFmted[expName] = {
        Variant: experimentAssignments[expName],
      };
    }
  }
  const experimentOverridesFmted: Record<string, Record<string, string>> = {};
  if (!!experimentOverrides) {
    for (const expName of Object.keys(experimentOverrides)) {
      experimentOverridesFmted[expName] = {
        Variant: experimentOverrides[expName],
      };
    }
  }
  const handleSetOverride = React.useCallback(
    (name: string, _param: string, variant: string) => {
      if (!variant) {
        setExperimentOverrides(lodashOmit(experimentOverrides, name));
      } else {
        const value = {
          ...experimentOverrides,
          [name]: variant,
        };
        setExperimentOverrides(value);
      }
    },
    [experimentOverrides, setExperimentOverrides]
  );
  return (
    <ExperimentOverride
      experimentOverrideOptions={experimentOverridesOptions}
      experimentAssignments={experimentAssignmentsFmted}
      experimentOverrides={experimentOverridesFmted}
      setExperimentOverride={handleSetOverride}
      label={"ExperimentsService"}
    />
  );
}

export default function ExperimentOverrideContainer() {
  const areOverridesEnabled = useExperimentOverridesEnabled();
  const [open, setOpen] = React.useState(false);
  const [hide, setHide] = React.useState(false);
  const [displayAtBottom, setDisplayAtBottom] = React.useState(true);
  if (!areOverridesEnabled) {
    return null;
  }
  return hide ? null : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "fixed",
        zIndex: 1000,
        backgroundColor: "common.white",
        ...(displayAtBottom ? { bottom: "0.5em" } : { top: "0.5em" }),
      }}
    >
      <Box flex sx={{ justifyContent: "space-between", marginBottom: -8 }}>
        <IconButton
          icon={displayAtBottom ? faArrowUp : faArrowDown}
          onClick={() => setDisplayAtBottom(!displayAtBottom)}
          sx={{ width: 30, height: 30 }}
        />
        <IconButton
          icon={faTimes}
          onClick={() => setHide(true)}
          sx={{ width: 30, height: 30 }}
        />
      </Box>
      <Card
        onClick={() => {
          if (!open) {
            setOpen(true);
          }
        }}
        sx={{ cursor: !open ? "hover" : "default", p: "small", m: "small" }}
      >
        <Box
          flex
          sx={{
            flexDirection: "column",
          }}
        >
          <Button
            onClick={() => setOpen(!open)}
            sx={{
              marginX: "auto",
            }}
          >
            <span
              role="img"
              aria-label="Click to open experiment overrides menu"
            >
              🎩 📊
            </span>
            {open && " Click to collapse"}
          </Button>
        </Box>
      </Card>
      {open && (
        <>
          <ServerSideExperimentOverride />
        </>
      )}
    </Box>
  );
}
