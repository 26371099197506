import React, { Dispatch, SetStateAction } from "react";

import useLocalStorageReference from "./useLocalStorageReference";

function useLocalStorageState<T>(
  key: string,
  defaultValue?: T
): [T, Dispatch<SetStateAction<T>>] {
  const { canUseLocalStorage, localStorage } = useLocalStorageReference();
  const [value, _setValue] = React.useState<T>(() => {
    try {
      const storedValue = localStorage?.getItem(key);
      const parsedValue = storedValue ? JSON.parse(storedValue) : null;
      return parsedValue ?? defaultValue;
    } catch (e) {
      OsPlatform.captureError(e, { extra: { localStorageKey: key } });
      return defaultValue;
    }
  });

  const saveToStorage = React.useCallback(
    value => {
      if (canUseLocalStorage && value !== undefined) {
        localStorage!.setItem(key, JSON.stringify(value));
      }
    },
    [canUseLocalStorage, localStorage, key]
  );

  React.useEffect(() => {
    saveToStorage(value);
  }, [value, saveToStorage]);

  const setValue = React.useCallback(
    value => {
      _setValue(value);
      saveToStorage(value);
    },
    [saveToStorage]
  );

  return [value, setValue];
}

export default useLocalStorageState;
