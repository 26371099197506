import { Component } from "@outschool/ownership-areas";
import { OsEnvironment } from "@outschool/platform";
import {
  getSentryInitBase,
  initializePlatformBrowser,
} from "@outschool/platform-browser/init";
import * as Sentry from "@sentry/nextjs";

import * as Env from "../Env";

export function initializePlatformClient() {
  const gitCommit = Env.GIT_COMMIT || "000000";
  const env: OsEnvironment = {
    envType: Env.OS_ENV_TYPE,
    appName: "server-rendered-website",
    appWorker: "Client",
    workerType: "browser",
    appEnv:
      Env.OS_APP_ENV || (Env.OS_ENV_TYPE === "test" ? "Test" : "Development"),
    alarmComponent: Component.ServerRenderedWebsite,
    gitCommit,
    osUserAgent: `SSR-Client/${gitCommit} (browser)`,
  };

  Sentry.init(getSentryInit(env));
  initializePlatformBrowser({
    env,
    // pass only the required functions to keep tree-shaking functional
    sentry: { getClient: () => Sentry.getClient() },
  });
}

function getSentryInit(env: OsEnvironment) {
  let result: Sentry.BrowserOptions = {
    ...getSentryInitBase(env),
    enabled: Env.SENTRY_ENABLED && env.envType !== "test",
    dsn: Env.SENTRY_DSN,
    attachStacktrace: true,
    integrations: function (integrations) {
      return integrations.filter(
        integration => integration.name !== "Breadcrumbs"
      );
    },
    maxValueLength: 500,
    tracesSampler: function () {
      return 0.1;
    },
  };

  return result;
}
